html, body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

#app {
  width: 100vw;
  height: 100vh;
}

#fullscreen-instruction {
  position: absolute;
  z-index: 10000;
  top: 170px;
  left: 0;
  right: 0;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  font-size: 54px;
  text-align: center;
  cursor: pointer;
}

.mapboxgl-ctrl-logo {
  display: none !important;
}

@media all and (display-mode: fullscreen) {
  #fullscreen-instruction {
    display: none;
  }
  .mapboxgl-ctrl-shrink {
    display: none !important;
  }
}

@media only screen and (max-width: 600px) {
  #fullscreen-instruction {
    display: none;
  }
}

.highlight-marker {
  background-color: rgba(255, 255, 255, 0.9);
  padding: 12px 24px;
  border-radius: 8px;
  overflow: hidden;
  width: 350px;
  height: 250px;
}

.highlight-marker h2 {
  font-size: 25px;
  line-height: 120%;
}

.highlight-marker p {
  font-size: 19px;
}

.circle-marker {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: solid 0.5px #fff;
  box-shadow: 0 4px 4px 0 rgba(21, 20, 20, 0.2);
  position: relative;

  > img {
    width: 32px;
    height: 32px;
  }

  &.emoji-marker {
    background-color: #fff;
  }

  .emoji {
    position: absolute;
    font-size: 22px;
    top: 5px;
    left: 3px;
  }
}

#live-box {
  position: absolute;
  width: 120px;
  height: 120px;
  z-index: 10000;
  top: 0;
  left: 0;
  padding: 10px;
  background-color: #fff;
  box-shadow: 0 4px 4px 0 rgba(21, 20, 20, 0.2);
  border-bottom-right-radius: 10px;;

  text-align: center;
  font-size: 15px;
  color: #b6191f;
  text-transform: uppercase;

  @media only screen and (max-width: 600px) {
    width: 70px;
    height: 70px;
  }
}

#radar-svg {
  width: 100px;
  height: 100px;
  animation: rotate 5s infinite;
  animation-timing-function: linear;

  @media only screen and (max-width: 600px) {
    width: 50px;
    height: 50px;
  }
}

@keyframes rotate {
  from {
      transform: rotate(0deg);
  }

  to {
      transform: rotate(360deg);
  }
}

.highlight-list {
  position: absolute;
  z-index: 10000;
  top: 25px;
  right: 60px;
  width: 400px;
  min-height: 100px;
  max-height: 200px;
  overflow: auto;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 4px 0 rgba(21, 20, 20, 0.2);
  padding: 15px;

  @media only screen and (max-width: 600px) {
    top: auto;
    right: auto;
    bottom: 0;
    left: 0;
    width: 100%;
  }
}

.highlight-row {
  padding: 3px;

  & a {
    color: #1d1d1d;
  }

  & .highlight-row a:hover {
    text-decoration: underline;
  }

  & .highlight-row a:visited {
    color: purple;
  }

  & .highlight-row a:active {
    color: grey;
  }

  & td {
    padding-bottom: 8px;
  }

  & td:first-child {
    padding-right: 8px;
    font-size: 13px;
  }

  .time {
    cursor: default;
  }

  .icon {
    margin-right: 3px;
    cursor: default;
  }

  .icon img {
    width: 20px;
    margin-right: 4px;
  }

  .active {
    color: #b6191f;
    margin-left: 2px;
    animation: pulseAnimation 3s infinite;
  }

  .rank {
    display: inline-block;
    margin-left: 5px;
    font-size: 12px;
    color: #b6191f;
    font-weight: bold;
    padding: 1px 5px;
    border-radius: 50%;
    border: solid 1px #d6a5a6;
    cursor: default;
  }
}

@keyframes pulseAnimation {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.1);
    opacity: 0.3;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}