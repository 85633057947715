html, body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

#app {
  width: 100vw;
  height: 100vh;
}

#fullscreen-instruction {
  z-index: 10000;
  color: #fff;
  text-align: center;
  cursor: pointer;
  background-color: #00000080;
  padding: 10px;
  font-size: 54px;
  position: absolute;
  top: 170px;
  left: 0;
  right: 0;
}

.mapboxgl-ctrl-logo {
  display: none !important;
}

@media (display-mode: fullscreen) {
  #fullscreen-instruction {
    display: none;
  }

  .mapboxgl-ctrl-shrink {
    display: none !important;
  }
}

@media only screen and (width <= 600px) {
  #fullscreen-instruction {
    display: none;
  }
}

.highlight-marker {
  background-color: #ffffffe6;
  border-radius: 8px;
  width: 350px;
  height: 250px;
  padding: 12px 24px;
  overflow: hidden;
}

.highlight-marker h2 {
  font-size: 25px;
  line-height: 120%;
}

.highlight-marker p {
  font-size: 19px;
}

.circle-marker {
  border: .5px solid #fff;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  position: relative;
  box-shadow: 0 4px 4px #15141433;
}

.circle-marker > img {
  width: 32px;
  height: 32px;
}

.circle-marker.emoji-marker {
  background-color: #fff;
}

.circle-marker .emoji {
  font-size: 22px;
  position: absolute;
  top: 5px;
  left: 3px;
}

#live-box {
  z-index: 10000;
  text-align: center;
  color: #b6191f;
  text-transform: uppercase;
  background-color: #fff;
  border-bottom-right-radius: 10px;
  width: 120px;
  height: 120px;
  padding: 10px;
  font-size: 15px;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 4px 4px #15141433;
}

@media only screen and (width <= 600px) {
  #live-box {
    width: 70px;
    height: 70px;
  }
}

#radar-svg {
  width: 100px;
  height: 100px;
  animation: 5s linear infinite rotate;
}

@media only screen and (width <= 600px) {
  #radar-svg {
    width: 50px;
    height: 50px;
  }
}

@keyframes rotate {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

.highlight-list {
  z-index: 10000;
  background-color: #fff;
  border-radius: 10px;
  width: 400px;
  min-height: 100px;
  max-height: 200px;
  padding: 15px;
  position: absolute;
  top: 25px;
  right: 60px;
  overflow: auto;
  box-shadow: 0 4px 4px #15141433;
}

@media only screen and (width <= 600px) {
  .highlight-list {
    width: 100%;
    inset: auto auto 0 0;
  }
}

.highlight-row {
  padding: 3px;
}

.highlight-row a {
  color: #1d1d1d;
}

.highlight-row .highlight-row a:hover {
  text-decoration: underline;
}

.highlight-row .highlight-row a:visited {
  color: purple;
}

.highlight-row .highlight-row a:active {
  color: gray;
}

.highlight-row td {
  padding-bottom: 8px;
}

.highlight-row td:first-child {
  padding-right: 8px;
  font-size: 13px;
}

.highlight-row .time {
  cursor: default;
}

.highlight-row .icon {
  cursor: default;
  margin-right: 3px;
}

.highlight-row .icon img {
  width: 20px;
  margin-right: 4px;
}

.highlight-row .active {
  color: #b6191f;
  margin-left: 2px;
  animation: 3s infinite pulseAnimation;
}

.highlight-row .rank {
  color: #b6191f;
  cursor: default;
  border: 1px solid #d6a5a6;
  border-radius: 50%;
  margin-left: 5px;
  padding: 1px 5px;
  font-size: 12px;
  font-weight: bold;
  display: inline-block;
}

@keyframes pulseAnimation {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  50% {
    opacity: .3;
    transform: scale(1.1);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}
/*# sourceMappingURL=index.53037e4a.css.map */
